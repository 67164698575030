


































import { copyToClipBoard } from '@/helpers/copyToClipBoard';

export default {
  name: 'CodeBlockWrapper',
  props: {
    value: {
      type: String,
      default: undefined
    },
    bordered: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: undefined
    },
    type: {
      type: Object,
      default: (): any => ({})
    },
    copy: {
      type: Boolean,
      default: true
    },
    caption: {
      type: String,
      default: undefined
    },
    customCopy: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      showTooltip: false
    };
  },
  methods: {
    addToClipboard(): void {
      if (this.showTooltip) return;

      this.showTooltip = true;
      copyToClipBoard(this.value, null, true);
      setTimeout(() => (this.showTooltip = false), 1000);
    }
  }
};
