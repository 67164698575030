











































import CodeBlockWrapper from './CodeBlockWrapper.vue';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';

export default {
  name: 'CodeBlock',
  components: { CodeBlockWrapper },
  props: {
    title: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: ''
    },
    copy: {
      type: Boolean,
      default: true
    },
    hint: {
      type: String,
      default: undefined
    },
    iconHint: {
      type: String,
      default: undefined
    },
    hide: {
      type: Boolean,
      default: true
    },
    customCopy: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      isHidden: true,
      showTooltip: false
    };
  },
  computed: {
    valueText(): string {
      if (this.isHidden && this.hide && this.value?.length)
        return '*'.repeat(this.value.length);

      return this.value;
    }
  },
  methods: {
    addToClipboard(): void {
      this.showTooltip = true;
      copyToClipBoard(this.value, null, true);
      this.$emit('copied');
      setTimeout(() => (this.showTooltip = false), 500);
    }
  }
};
