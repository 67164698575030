import { ERole } from '@/api/schema';
import store from '@/store/index';

export type userRoleItem = {
  value: ERole;
  label: string;
  description: string;
};

export const userRoles: readonly userRoleItem[] = Object.freeze([
  {
    value: ERole.ROLE_OPERATOR_ADMIN,
    label: 'Admin',
    description: 'Can grant any access except for Account'
  },
  {
    value: ERole.ROLE_OPERATOR_MARKETER,
    label: 'Marketing Manager',
    description: 'Can only grant Marketer access'
  },
  {
    value: ERole.ROLE_OPERATOR_DEVELOPER,
    label: 'Developer',
    description: 'Can only grant Developer access'
  },
  {
    value: ERole.ROLE_OPERATOR_FINANCE,
    label: 'Finance',
    description: 'Can only grant Finance access'
  },
  {
    value: ERole.ROLE_OPERATOR_SUPPORT,
    label: 'Pitboss',
    description: 'Does not have the ability to grant access'
  },
  {
    value: ERole.ROLE_OPERATOR_GUEST,
    label: 'Support',
    description: 'Does not have the ability to grant access'
  },
  {
    value: ERole.ROLE_OPERATOR_VIEWER,
    label: 'Viewer',
    description: 'Does not have the ability to grant access'
  }
]);

export const userCopyStakeRoles: readonly userRoleItem[] = Object.freeze([
  {
    value: ERole.ROLE_OPERATOR_COPY_STAKE_ADMIN,
    label: store.getters['app/isCopyStakeRole'] ? 'Admin' : 'CopyStake Admin',
    description: 'Can grant any access except for Account'
  },
  {
    value: ERole.ROLE_OPERATOR_COPY_STAKE_SUPPORT,
    label: store.getters['app/isCopyStakeRole']
      ? 'Support'
      : 'CopyStake Support',
    description: 'Does not have the ability to grant access'
  }
]);

export const allUserRoles: readonly userRoleItem[] = Object.freeze([
  ...userRoles,
  ...userCopyStakeRoles
]);
