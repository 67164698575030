











































import { isEmail, required } from '@/helpers/validation';
import InviteUserSelect from '@/components/invite-user/InviteUserSelect.vue';
import { EOnbordingStepStatus, EOnbordingStep, ERole } from '@/api/schema';
import { allUserRoles } from '@/helpers/inviteUserHelpers';
import { PropType } from 'vue';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import { sendInviteByEmail, TInvitePayload } from '@/api/Auth';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  getOnboardingProgress,
  updateOnboardingProgress
} from '@/api/Onboarding';

export default {
  name: 'InviteUserInput',
  components: {
    InviteUserSelect
  },
  props: {
    type: {
      type: String as PropType<ERole>
    },
    value: {
      type: String,
      default: ''
    },
    link: {
      type: Boolean,
      default: false
    },
    selectedOperatorsIds: {
      type: Array,
      default: (): [] => []
    },
    disableButton: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      processing: false,
      showTooltip: false,
      formValid: true,
      emailRules: [(v) => required(v, 'E-mail'), isEmail],
      selectedType: ERole.ROLE_OPERATOR_ADMIN
    };
  },
  watch: {
    selectedType: {
      handler: 'updateType',
      immediate: true
    }
  },
  created(): void {
    if (this.type) {
      this.selectedType = this.type;
    } else if (!this.$role.is(['account', 'admin'])) {
      this.selectedType = this.$role.name;
    }
  },
  computed: {
    ...mapState('Onboarding', [
      'inviteLinks',
      'operators',
      'selectedOperator'
    ]),
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('Onboarding', ['currentAccount']),
    disabledInvite(): boolean {
      return typeof isEmail(this.email) === 'string';
    },
    disabledForm(): boolean {
      return this.isSuperAdmin; // disable functionality for superAdmin role DEV-2976
    },
    email: {
      get(): string {
        return this.value;
      },
      set(val: string): void {
        this.$emit('input', val);
      }
    },
    operatorIds(): number[] {
      if (!this.type && this.selectedOperatorsIds.length) {
        return this.selectedOperatorsIds;
      }

      return this.currentAccount.children.map(({ id }) => id);
    },
    currentLink(): string {
      const currentInvite = this.inviteLinks.find(
        ({ role }) => role === this.selectedType
      );

      if (currentInvite?.link) return currentInvite.link;

      const baseInvite = this.inviteLinks.find(
        ({ role }) => role === ERole.ROLE_OPERATOR_ADMIN
      );

      return baseInvite?.link;
    },
    typeLabel(): string {
      return allUserRoles.find(({ value }) => value === this.selectedType)
        ?.label;
    }
  },
  methods: {
    ...mapActions('Onboarding', [
      'updateOnboardingProgress',
      'getInviteLinks'
    ]),
    sendInvite(): void {
      this.processing = true;
      sendInviteByEmail({
        operators: this.operatorIds,
        login: this.email,
        role: this.selectedType
      } as TInvitePayload)
        .then(() => {
          this.$emit('invite');
          this.$emit('input', '');
          this.$toast.success('Invitation has been sent successfully.');
          this.saveProgress();
          if (this.selectedType === ERole.ROLE_OPERATOR_DEVELOPER) {
            window.dataLayer.push({
              event: 'invite_dev_section_finished',
              eventCategory: 'onboarding',
              eventAction: 'status_update',
              eventLabel: 'invite_dev_section_finished'
            });
          }
        })
        .catch(errorToastMessage)
        .finally(() => {
          this.processing = false;
        });
    },

    saveProgress(): void {
      if (
        this.$role.is(['admin', 'account', 'developer', 'marketer']) &&
        [
          ERole.ROLE_OPERATOR_DEVELOPER,
          ERole.ROLE_OPERATOR_SUPPORT,
          ERole.ROLE_OPERATOR_MARKETER
        ].includes(this.selectedType)
      ) {
        const mapStepType = {
          [ERole.ROLE_OPERATOR_DEVELOPER]: EOnbordingStep.inviteDevProgress,
          [ERole.ROLE_OPERATOR_SUPPORT]: EOnbordingStep.userSupportProgress,
          [ERole.ROLE_OPERATOR_MARKETER]:
            EOnbordingStep.inviteMarketingManagerProgress
        };
        const type = mapStepType[this.selectedType];

        if (type) {
          this.operatorIds.forEach((id: number) => {
            if (id === this.selectedOperator.id) return;

            getOnboardingProgress(id).then((currentProgress) =>
              updateOnboardingProgress(
                {
                  ...currentProgress,
                  [type]: EOnbordingStepStatus.COMPLETED
                },
                id
              )
            );
          });

          if (this.operatorIds.includes(this.selectedOperator.id)) {
            this.updateOnboardingProgress(type);
          }
        }
      }
    },

    addToClipboard(): void {
      if (this.showTooltip) return;
      this.$emit('invite');
      this.showTooltip = true;

      this.getInviteLinks(this.operatorIds).then(() => {
        copyToClipBoard(this.currentLink, null, true);
      });

      this.saveProgress();
      window.dataLayer.push({
        event: 'token_info_section_finished',
        eventCategory: 'onboarding',
        eventAction: 'status_update',
        eventLabel: 'invite_dev_section_finished'
      });
      setTimeout(() => (this.showTooltip = false), 1500);
    },
    updateType(val: string): void {
      this.$emit('type-update', val);
    }
  }
};
