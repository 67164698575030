




























import { ERole } from '@/api/schema';
import {
  allUserRoles,
  userCopyStakeRoles
} from '@/helpers/inviteUserHelpers';
import { PropType } from 'vue';

export default {
  name: 'InviteUserSelect',
  props: {
    value: {
      type: String as PropType<ERole>,
      default: allUserRoles[0].value
    },
    removable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    role: {
      handler(): void {
        this.selectedValue = this.userRolesList[0].value;
      },
      immediate: true
    }
  },
  computed: {
    selectedValue: {
      get(): ERole {
        return this.value;
      },
      set(val: ERole): void {
        this.$emit('input', val);
      }
    },
    role(): string {
      return this.$role.name;
    },
    userRolesList(): any {
      if (this.$role.is(['admin', 'account'])) {
        return allUserRoles;
      } else if (this.$role.is(['copystake-account', 'copystake-admin'])) {
        return userCopyStakeRoles;
      } else {
        return allUserRoles.filter(({ value }) => value === this.$role.name);
      }
    },
    itemsList(): any {
      return this.userRolesList.map((item) => ({
        ...item,
        disabled: this.readonly && item.value !== this.value
      }));
    }
  },
  methods: {
    handleRemove(): void {
      this.$emit('remove');
    }
  }
};
